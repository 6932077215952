<template>
  <v-container>
  <v-tabs v-model="activeTab" @change="tabChange">
    <v-tab :key="0">Editor</v-tab>
    <v-tab :key="1">Raw data</v-tab>
    <v-tab :key="2" v-if="debug">Applyed schema</v-tab>
    <v-tab :key="3" v-if="debug">Applyed UI</v-tab>
    <v-tab-item :key="0">
      <json-forms
        :data="objData"
        :schema="schema"
        :uischema="uischema"
        @change="onChange"
        :renderers="renderers"
      />
    </v-tab-item>
    <v-tab-item :key="1">
      <vue-monaco-editor
        v-model="code"
        height="600px"
        language="json"
        loading="false"
      />
    </v-tab-item>
    <v-tab-item :key="2">
      <vue-monaco-editor
        v-model="appliedSchema"
        height="600px"
        language="json"
        loading="false"
      />
    </v-tab-item>
    <v-tab-item :key="3">
      <vue-monaco-editor
        v-model="appliedUI"
        height="600px"
        language="json"
        loading="false"
      />
    </v-tab-item>
  </v-tabs>
  </v-container>
</template>

<script>
import { JsonForms } from "@jsonforms/vue2";
import { vuetifyRenderers } from "@jsonforms/vue2-vuetify";
import MapRender from "./renderers/MapRender";
import MonacoRender from "./renderers/MonacoRender";
import Vuetify from "vuetify";
import Vue from "vue";
import MonacoRenderVue from "./renderers/MonacoRender.vue";
import {getElementByScope} from '../../services/uiFormUtils';
import Ajv from 'ajv';
Vue.use(Vuetify);


export default {
  name: "formseditor",
  beforeMount: async function () {
    console.log("Before Mount");
    this.ajv = new Ajv({
      allErrors: true,
      verbose: true,
      strict: false,
    });
    let uiResp = await this.axios(
      "/assets/mock/uischema.json?" + new Date().getTime()
    );
    this.uischema = uiResp.data;
    let schemaResp = await this.axios(
      "/assets/mock/schema.json?" + new Date().getTime()
    );
    let initialSchema = schemaResp.data;
    initialSchema.properties.settings =
      initialSchema.settingsOptions[this.value.destinationType];
    this.schema = initialSchema;
    this.setValue(this.value);
  },
  props: ["value", "debug"],
  components: {
    JsonForms,
  },
  data: () => ({
    inited: false,
    renderers: [],
    code: "",
    objData: {},
    schema: {},
    uischema: {},
    activeTab: 0,
    appliedUI: "",
    appliedSchema: "",
    oldDestination: "",
  }),
  methods: {
    setValue: async function (value) {
      this.appliedSchema = JSON.stringify(this.schema, null, 2);
      this.appliedUI = JSON.stringify(this.uischema, null, 2);
      //let initial=await this.axios('/assets/mock/initial.json');
      this.objData = value;
      this.oldDestination = this.value.destinationType;
      this.updateDestinationMappingDataBeforeGenerationCode();
      const codeObj = {... this.objData};
      delete codeObj.errorMessage;
      delete codeObj.isValid;
      this.code = JSON.stringify(codeObj, null, 2);
      this.updateDestinationMappingDataAfterGeneratedCode();
      this.checkAndValidate(); 
      this.inited = true;
    },
    tabChange(event) {
      console.log(event);
      let preventClick = false;
      if (event == 0) {
        //form
        try {
          this.objData = JSON.parse(this.code);
        } catch {
          preventClick = true;
        }
      } else event == 1; //code
      {
        //TODO: validate
      }

      if (preventClick) {
        this.$nextTick(() => {
          this.tab = this.currentTab;
        });
      } else {
        this.currentTab = this.tab;
      }
    },
    checkAndValidate() 
    {
      const validate = this.ajv.compile(this.schema);
      const valid = validate(this.objData);
      if (valid === false) {
        const errorMessages = [];
        let errorMessage = "";
        validate.errors.forEach((error, index) => {
        if (error.keyword === "required") 
          {
            if (error.params && error.params.missingProperty) 
            { 
              errorMessages.push(`Property ${error.params.missingProperty} is required`);
            }
          } else 
          {
            if (!error.message.includes('must match "then" schema')) 
            {
              errorMessages.push(`Property '${error.instancePath.slice(1)}': ${error.message}`);
            }
          }});
          if (errorMessages.length === 0) 
          {
            errorMessage = "Internal Validation Error"
          } else 
          {
            errorMessage = errorMessages.join(" | ");
          }
          this.objData.errorMessage = errorMessage;
        }
        this.objData.isValid = valid;
    },
    testConnection: async function() 
    {
      let response = await this.apiCall(`/api/destinations/test-connection`, "POST",this.objData);
      if (response.status == 200) {
        this.showSuccess(response.data.payload.content.message);
      }
    },
    onChange(event) {
      console.log("step 2");
      if (this.inited) {
        // TODO: Marco (da verificare) trick rimpiazzabile con https://stackoverflow.com/questions/67163739/how-to-create-json-schema-for-dynamic-nested-map-of-objects
        console.log(event);

        if (event.data.settings == null) {
          event.data.settings = {};
          event.data.settings.formatSettings = {};
          event.data.settings.locationSettings ={};
        }

        let currentDestination = event.data.destinationType;
        if (this.oldDestination != currentDestination) {
          event.data.settings = {};
          this.oldDestination = currentDestination;
          if (event.data.destinationType == "file") {
            event.data.settings.formatSettings = {};
            event.data.settings.locationSettings = {};
          }
        }
        if (event.data.destinationType === "file" && event.data.settings.locationType !== null &&
            event.data.settings.locationType !== "FTPS" && event.data?.settings?.locationSettings?.encryptionMode != null) {
            delete event.data.settings.locationSettings.encryptionMode;
      }
        this.schema.properties.settings = this.schema.settingsOptions[currentDestination];
        this.appliedSchema = JSON.stringify(this.schema, null, 2);
        this.objData = event.data;
        this.updateDestinationMappingDataBeforeGenerationCode();
        const codeObj = {... this.objData};
        delete codeObj.errorMessage;
        delete codeObj.isValid;
        this.code = JSON.stringify(codeObj, null, 2);
        this.updateDestinationMappingDataAfterGeneratedCode();
        this.checkAndValidate(); 
        this.$emit("input", this.objData);
      }
    },

    updateDestinationMappingDataBeforeGenerationCode: function () {
      if (this.previousDestinationType === "File" && this.objData.settings.formatSettings != null &&
          typeof this.objData.settings.formatSettings.mapping === "boolean") {
        delete this.objData.settings.formatSettings.mapping;
      }
      if (this.objData.destinationType == "file" && this.objData.settings.formatSettings != null &&
          typeof this.objData.settings.formatSettings.mapping === "string") {
        try {
          this.objData.settings.formatSettings.mapping = JSON.parse(this.objData.settings.formatSettings.mapping);
        }
        catch (e) {}
      }
    },
    updateDestinationMappingDataAfterGeneratedCode: function () {
      if (this.objData.destinationType == "file" && this.objData.settings.formatSettings != null &&
          typeof this.objData.settings.formatSettings.mapping === "object") {
        this.objData.settings.formatSettings.mapping = JSON.stringify(this.objData.settings.formatSettings.mapping, null, 2);
      }
    },
  },



  created: async function () {
    console.log("ITEM CREATED");
    const vrenders = vuetifyRenderers;
    vuetifyRenderers.push({
      tester: function (schema, schemaPath) {
        try {
          let item = getElementByScope(schemaPath, schema.scope);
          if (item.options.elementLabelProp == "map") {
            return 10;
          }
        } catch {}
        return -1;
      },
      renderer: MapRender,
    });

    vuetifyRenderers.push({
      tester: function (schema, schemaPath) {
        try {
          let item = getElementByScope(schemaPath, schema.scope);
          if (item.options.elementLabelProp == "monaco") {
            return 10;
          }
        } catch {}
        return -1;
      },
      renderer: MonacoRender,
    });

    this.renderers = vuetifyRenderers;

    console.log(this.objData);
    //this.$options.components.JsonForms.renderers.push(...vuetifyRenderers);
  },
  watch: {
    value: async function (oldvalue, newValue) {
      console.log("step 1");
      this.inited = false;
      if (oldvalue.destinationType != newValue.destinationType) {
        let uiResp = await this.axios(
            "/assets/mock/uischema.json?" + new Date().getTime()
        );
        this.uischema = uiResp.data;
        let schemaResp = await this.axios(
            "/assets/mock/schema.json?" + new Date().getTime()
        );
        let initialSchema = schemaResp.data;
        initialSchema.properties.settings =
            initialSchema.settingsOptions[this.value.destinationType];
        this.schema = initialSchema;
      }
      this.setValue(this.value);
    },
  },
};
</script>
