<template>
  <v-container fluid>
    <v-row class="ips-row">
      <v-card-text>
        Memory Buffer Data
        <v-icon @click="getBufferData" class="reload-icon" color="primary">
          mdi-reload
        </v-icon>
        <span class="float-right fw-normal h5 mt-2 font-sm">Updated at {{ currentHour }}</span>
      </v-card-text>
      <v-progress-circular v-if="loadingBuffer" indeterminate color="primary" size="50" class="mx-auto my-4"></v-progress-circular>
      <v-simple-table dense class="mb-5 vw-100" v-if="!loadingBuffer"  :key="tableKey">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">Flow</th>
            <th class="text-left">FlowId</th>
            <th class="text-left">Buffer Messages</th>
            <th class="text-left">Last Enqueue</th>
            <th class="text-left">Next Debounce</th>
            <th class="text-left">Min Message To Egress</th>
            <th class="text-left">Logical Next Messages To Buffer</th>
            <th class="text-left">Egress Execution Active</th>
            <th class="text-left">Topic Is Banned</th>

          </tr>
          </thead>
          <tbody>
          <tr v-for="(flow, i) in flows" :key="i">
            <td style="cursor:pointer;" @click="view(flow.id)">{{ flow.name }}</td>
            <td>{{ flow.id }}</td>
            <td>{{bufferDataResponse.BufferData?.find(item => item.Topic === (flow.tenantId + "_" + flow.id)) ?
                bufferDataResponse.BufferData?.find(item => item.Topic === (flow.tenantId + "_" + flow.id)).Count : 0}}</td>
            <td>{{bufferDataResponse.BufferData?.find(item => item.Topic === (flow.tenantId + "_" + flow.id)) ?
                moment(bufferDataResponse.BufferData?.find(item => item.Topic === (flow.tenantId + "_" + flow.id)).LastEnqueueDateTime).format("DD/MM/YYYY HH:mm:ss"): 0}}</td>
            <td>{{bufferDataResponse.BufferData?.find(item => item.Topic === (flow.tenantId + "_" + flow.id)) ?
                moment(bufferDataResponse.BufferData?.find(item => item.Topic === (flow.tenantId + "_" + flow.id)).NextDebounceDateTime).format("DD/MM/YYYY HH:mm:ss"): 0}}</td>
            <td>{{bufferDataResponse.BufferData?.find(item => item.Topic === (flow.tenantId + "_" + flow.id)) ?
                bufferDataResponse.BufferData?.find(item => item.Topic === (flow.tenantId + "_" + flow.id)).MinMessagesToEgress : ""}}</td>
            <td>{{bufferDataResponse.BufferData?.find(item => item.Topic === (flow.tenantId + "_" + flow.id)) ?
                bufferDataResponse.BufferData?.find(item => item.Topic === (flow.tenantId + "_" + flow.id)).LogicalNextMessagesToBuffer : ""}}</td>
            <td>{{egressDataResponse.EgressData?.find(item => item.Topic === (flow.tenantId + "_" + flow.id)) ?
                egressDataResponse.EgressData?.find(item => item.Topic === (flow.tenantId + "_" + flow.id)).Active : ""}}</td>
            <td>{{bufferDataResponse.BufferData?.find(item => item.Topic === (flow.tenantId + "_" + flow.id)) ?
                bufferDataResponse.BufferData?.find(item => item.Topic === (flow.tenantId + "_" + flow.id)).TopicIsBanned : ""}}</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";

export default {
  name: "BufferStatus",
  props: {
    flowId: {
      type: String,
      required: false,
    },
  },
  computed: {
    moment() {
      return moment
    }
  },
  beforeMount: async function () {
    await this.getBufferData();

  },
  mounted : function () {
    this.interval = setInterval(async () => {
      await this.getBufferData(false);
    }, 5000);
  },
  methods: {
    getBufferData : async function (loading = true) {
      if (loading) this.loadingBuffer = true;
      let response = await this.apiCall(
          "/api/editableflows/list",
          "POST",
          {
            "orderBy": [
              {
                "name": "name",
                "direction": "asc"
              }]
          },
          {}
      );
      console.log(response.data.payload.content);
      if (response.status == 200) {
        let flows = response.data.payload.content;
        this.flows = flows;
      }
      var responseBufferService = await this.apiCall(
          "/api/dashboard/BufferStatus",
          "GET",
          {},
          {}
      );
      console.log(responseBufferService.data);
      if (responseBufferService.status == 200) {
        this.bufferDataResponse = responseBufferService.data;
      }

      var responseEgressStatus = await this.apiCall(
          "/api/dashboard/EgressStatus",
          "GET",
          {},
          {}
      );
      console.log(responseEgressStatus.data);
      if (responseEgressStatus.status == 200) {
        this.egressDataResponse = responseEgressStatus.data;
      }


      const now = new Date();
      this.currentHour = moment(now).format("DD/MM/YYYY HH:mm:ss");
      await this.delay(1000); // Attendi 2 secondi
      if (loading) this.loadingBuffer = false;
      this.tableKey += 1;
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    view: async function (item) {
      window.open(this.$router.resolve({ name: "flows.view", params: { id: item } }).href, '_blank');
    },
  },
  data: () => ({
    loadingBuffer: false,
    flows: {},
    bufferDataResponse: {},
    egressDataResponse: {},
    tableKey: 0,
    currentHour: ""
  }),
};
</script>


<!-- {
  text: "_id",
  sortable: false,
  value: "_id",
}, -->
